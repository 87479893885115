import { WarningFilled } from "@/components/Icons";
import type { ReactNode } from "react";
import type { FieldError } from "react-hook-form";

type FormItemProps = {
    label?: string;
    helperText?: string;
    error?: FieldError;
    children?: ReactNode;
};

export const FormItem = ({
    label,
    helperText,
    error,
    children,
}: FormItemProps): JSX.Element => (
    <label
        className="group flex flex-col items-start gap-1"
        data-error={error?.message ? "true" : "false"}
    >
        <span className="typography-h5 font-bold text-blue-grey empty:hidden">
            {label}
        </span>
        {children}
        <span className="typography-tiny text-blue-grey empty:hidden">
            {helperText}
        </span>
        <span className="typography-tiny flex items-center gap-1 font-bold text-destructive-600 empty:hidden">
            <WarningFilled className="size-[14px] group-data-[error=false]:hidden" />
            {error?.message}
        </span>
    </label>
);
