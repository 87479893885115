import type { SVGProps } from "./types";

export const WarningFilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.39091 4.51611C9.09672 3.26135 10.9033 3.26135 11.6091 4.51611L16.7602 13.6737C17.4525 14.9043 16.5631 16.425 15.1511 16.425H4.84887C3.43686 16.425 2.54753 14.9043 3.23978 13.6737L8.39091 4.51611ZM10.923 13.6558C10.923 14.1656 10.5097 14.5789 9.99992 14.5789C9.49011 14.5789 9.07683 14.1656 9.07683 13.6558C9.07683 13.146 9.49011 12.7327 9.99992 12.7327C10.5097 12.7327 10.923 13.146 10.923 13.6558ZM9.99992 6.27107C9.49011 6.27107 9.07683 6.68435 9.07683 7.19416V9.96344C9.07683 10.4732 9.49011 10.8865 9.99992 10.8865C10.5097 10.8865 10.923 10.4732 10.923 9.96344V7.19416C10.923 6.68435 10.5097 6.27107 9.99992 6.27107Z"
            fill="currentColor"
        />
    </svg>
);
