import { cx } from "class-variance-authority";
import { type InputHTMLAttributes, type ReactNode, forwardRef } from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    prefixDiv?: ReactNode;
    suffixDiv?: ReactNode;
    error?: boolean;
}

const style = {
    normal: "ring-1 ring-blue-grey-50 bg-white focus-within:ring-2 focus-within:ring-primary hover:ring-2 hover:ring-primary active:ring-primary",
    disabled:
        "ring-1 ring-blue-grey-50 bg-blue-grey-20 text-blue-grey-300 placeholder:text-blue-grey-300",
    error: "ring-destructive-600 ring-2",
};
const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        { prefixDiv, suffixDiv, defaultValue, value, placeholder, ...props },
        ref,
    ): JSX.Element => {
        let borderCss = style.normal;
        if (props.disabled) borderCss = style.disabled;
        else if (props.error) borderCss = style.error;
        return (
            <div
                className={cx(
                    "typography-main flex h-10 items-center gap-3 rounded-lg px-3 py-2.5",
                    borderCss,
                    props.className,
                )}
            >
                {prefixDiv && <div>{prefixDiv}</div>}
                <input
                    ref={ref}
                    placeholder={placeholder}
                    value={value}
                    defaultValue={defaultValue}
                    {...props}
                    className="h-10 w-full rounded-md border-none bg-transparent p-0 text-left text-blue-grey-900 caret-primary-500 outline-none placeholder:text-blue-grey-400 disabled:text-blue-grey-400"
                />
                {suffixDiv && <div>{suffixDiv}</div>}
            </div>
        );
    },
);
Input.displayName = "Input";

export { Input };
