type Param = string | string[] | undefined;

/**
 * Attempts to coerce a query param into a single string. If param is undefined,
 * will turn into an empty string.
 */
export const stringParam = (p: Param): string => {
    if (Array.isArray(p)) return p[0] ?? "";
    return p ?? "";
};

/**
 * Attempts to coerce a query param into a boolean. Will return true only if the
 * query param is `"true"`.
 */
export const boolParam = (p: Param): boolean => {
    if (Array.isArray(p)) return p[0] === "true";
    return p === "true";
};

/**
 * Attempts to coerce a query param into a string array. If param is undefined,
 * an empty list is returned.
 */
export const stringArrayParam = (p: Param): string[] => {
    if (!p) return [];
    if (Array.isArray(p)) return p;
    return [p];
};
