import { useMutation } from "@apollo/client";
import { captureException } from "@sentry/nextjs";
import { cx } from "class-variance-authority";
import {
    FacebookAuthProvider,
    GoogleAuthProvider,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
} from "firebase/auth";
import Link from "next/link";
import { useRouter } from "next/router";
import { type ReactNode, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Button } from "@/components/Button";
import { Input } from "@/components/Forms";
import {
    CheckUnfilled,
    CrossUnfilled,
    EyeSlashUnfilled,
    EyeUnfilled,
} from "@/components/Icons";
import { SocialColoredIcon } from "@/components/Icons/SocialColored";
import { Loader } from "@/components/Loader";
import { auth } from "@/lib/firebase/auth";
import { graphql } from "@/lib/gql";

const CREATE_USER_PROFILE = graphql(`
    mutation createUserProfile(
        $name: String!
        $email: EmailAddress
        $phoneNumber: PhoneNumber
    ) {
        createUserProfile(
            request: { name: $name, email: $email, phoneNumber: $phoneNumber }
        ) {
            __typename
            ... on AccessError {
                message
            }
            ... on InvalidValue {
                message
            }
        }
    }
`);

type EmailFormProps = { onSubmit?: (v: EmailFormValues) => Promise<void> };
type LinkTextProps = { href: string; children: ReactNode };
type EmailFormValues = { email: string; password: string };
type ErrorDivProps = { error: boolean; message: string };
type AuthError = { code: string; message: string };
type FormProps = { className?: string };
type FormValues = {
    name: string;
    phoneNumber: string | null;
    email: string | null;
};

// TODO: mobile signup form
// TODO: redirect to verify email
const LoginForm = ({ className }: FormProps): JSX.Element => {
    const { t, ready } = useTranslation([
        "components/Forms/AuthForm",
        "common",
    ]);
    const [loading, setLoading] = useState(false);

    const router = useRouter();

    const [createUserProfile] = useMutation(CREATE_USER_PROFILE, {
        onCompleted: (data) => {
            if (data.createUserProfile.__typename === "UserAlreadyExists")
                toast.success(
                    t("successfullySignedIn", "Successfully signed in"),
                );
            else if (data.createUserProfile.__typename === "UserProfileCreated")
                auth.currentUser?.reload();
            else toast.error(data.createUserProfile.message);
        },
        onError: () => toast.error(t("failedSignUp", "Failed sign up")),
    });

    const handleCreateUserProfile = (values: FormValues): void => {
        const variables = {
            name: values.name,
            phoneNumber: values.phoneNumber,
            email: values.email,
        };
        createUserProfile({ variables });
    };

    const handleLogin = async (v: EmailFormValues): Promise<void> => {
        setLoading(true);
        await signInWithEmailAndPassword(auth, v.email, v.password).catch(
            handleLoginError,
        );
        setLoading(false);
    };

    const handleFacebookLogin = (): void => {
        signInWithPopup(auth, new FacebookAuthProvider())
            .then(({ user }) => {
                handleCreateUserProfile({
                    name: user.displayName ?? "",
                    email: user.email ?? null,
                    phoneNumber: user.phoneNumber ?? null,
                });
            })
            .catch(handleSocialAuthError);
    };

    const handleLoginError = (err: AuthError): void => {
        switch (err.code) {
            case "auth/user-disabled":
                toast.error(
                    t("error_login.user_disabled", {
                        defaultValue:
                            "Your account has been disabled. Please contact contact@courtsite.my",
                    }),
                );
                break;
            case "auth/invalid-email":
                toast.error(
                    t("error_login.invalid_email", {
                        defaultValue: "E-mail address entered is invalid.",
                    }),
                );
                break;
            case "auth/user-not-found":
                toast.error(
                    t("error_login.wrong_user_password", {
                        defaultValue:
                            "Email/Password entered is incorrect. Try again.",
                    }),
                );
                break;
            case "auth/wrong-password":
                toast.error(
                    t("error_login.wrong_user_password", {
                        defaultValue:
                            "Email/Password entered is incorrect. Try again.",
                    }),
                );
                break;
            default:
                // auth/operation-not-allowed
                toast.error(
                    t("error_login.default", {
                        defaultValue:
                            "An unknown error occurred, please try again later.",
                    }),
                );
                captureException(err, (ctx) =>
                    ctx.setExtras({
                        error: err,
                        errorCode: err.code,
                        errorMessage: err.message,
                    }),
                );
                break;
        }
    };

    const handleGoogleLogin = (): void => {
        signInWithPopup(auth, new GoogleAuthProvider())
            .then(({ user }) => {
                handleCreateUserProfile({
                    name: user.displayName ?? "",
                    email: user.email ?? null,
                    phoneNumber: user.phoneNumber ?? null,
                });
            })
            .catch(handleSocialAuthError);
    };

    const handleSocialAuthError = (err: AuthError): void => {
        switch (err.code) {
            case "auth/account-exists-with-different-credential":
                toast.error(
                    t(
                        "error_social_signup.email_used",
                        "E-mail address entered is already in use.",
                    ),
                );
                break;
            case "auth/cancelled-popup-request":
                toast.error(
                    t(
                        "error_social_signup.login_progress",
                        "Log in in progress.",
                    ),
                );
                break;
            case "auth/popup-closed-by-user":
                toast.error(
                    t(
                        "errorSocialPopupClosed",
                        "The popup has been closed by the user before finalizing the operation.",
                    ),
                );
                break;
            case "auth/operation-not-allowed":
                toast.error(
                    t(
                        "error_social_signup.not_allowed",
                        "Social Log in not allowed for your account.",
                    ),
                );
                break;
            case "auth/popup-blocked":
                toast.error(
                    t("error_social_signup.popup", "Popup is blocked."),
                );
                break;
            default:
                // auth/operation-not-allowed
                toast.error(
                    t(
                        "error_social_signup.default",
                        "An unknown error occurred, please try again later.",
                    ),
                );
                captureException(err, (ctx) =>
                    ctx.setExtras({
                        error: err,
                        errorCode: err.code,
                        errorMessage: err.message,
                    }),
                );
                break;
        }
    };

    if (!ready) return <div />;
    return (
        <Loader
            className={cx("flex flex-col gap-[52px]", className)}
            loading={loading}
        >
            <div className="flex flex-col gap-2">
                <span className="typography-h2 font-bold text-blue-grey-900">
                    {t("common:logIn", "Log In")}
                </span>
                <span className="text-blue-grey-900">
                    {t("noAccount", "Don't have a Courtsite account yet?")}
                    <Link
                        href={{ pathname: "/signup", query: router.query }}
                        passHref
                    >
                        <span className="ml-1 cursor-pointer font-bold text-primary-600">
                            {t("common:signUp", "Sign Up")}
                        </span>
                    </Link>
                </span>
            </div>
            <EmailLoginForm onSubmit={handleLogin} />
            <div className="flex flex-col gap-6">
                <div className="flex items-center gap-2">
                    <Line />
                    <span className="font-bold text-blue-grey">
                        {t("or", "or")}
                    </span>
                    <Line />
                </div>
                <div className="flex flex-col gap-3">
                    <Button
                        className="flex w-full gap-2"
                        variant="tertiary"
                        onClick={handleFacebookLogin}
                    >
                        <SocialColoredIcon
                            type="Facebook"
                            props={{ className: "size-5" }}
                        />
                        <span>
                            {t("continueWith", "Continue with {{context}}", {
                                context: "Facebook",
                            })}
                        </span>
                    </Button>
                    <Button
                        className="flex w-full gap-2"
                        variant="tertiary"
                        onClick={handleGoogleLogin}
                    >
                        <SocialColoredIcon
                            type="Google"
                            props={{ className: "size-5" }}
                        />
                        <span>
                            {t("continueWith", "Continue with {{context}}", {
                                context: "Google",
                            })}
                        </span>
                    </Button>
                </div>
            </div>
        </Loader>
    );
};

const SignupForm = ({ className }: FormProps): JSX.Element => {
    const { t, ready } = useTranslation([
        "components/Forms/AuthForm",
        "common",
    ]);
    const router = useRouter();

    const [createUserProfile] = useMutation(CREATE_USER_PROFILE, {
        onCompleted: (data) => {
            if (data.createUserProfile.__typename === "UserProfileCreated")
                auth.currentUser?.reload();
            else if (data.createUserProfile.__typename === "UserAlreadyExists")
                toast.success(
                    t("successfullySignedIn", "Successfully signed in"),
                );
            else toast.error(data.createUserProfile.message);
        },
        onError: () => {
            toast.error(t("failedSignUp", "Failed sign up"));
        },
    });

    const handleCreateUserProfile = (values: FormValues): void => {
        const variables = {
            name: values.name,
            phoneNumber: values.phoneNumber,
            email: values.email,
        };
        createUserProfile({ variables });
    };

    const handleSignup = async (v: EmailFormValues): Promise<void> => {
        await createUserWithEmailAndPassword(auth, v.email, v.password)
            .then(() => {
                handleCreateUserProfile({
                    email: v.email,
                    name: "",
                    phoneNumber: null,
                });
            })
            .catch(handleSignupError);
    };

    const handleFacebookLogin = (): void => {
        signInWithPopup(auth, new FacebookAuthProvider())
            .then(({ user }) => {
                handleCreateUserProfile({
                    name: user.displayName ?? "",
                    email: user.email ?? null,
                    phoneNumber: user.phoneNumber ?? null,
                });
            })
            .catch(handleSocialAuthError);
    };

    const handleGoogleLogin = (): void => {
        signInWithPopup(auth, new GoogleAuthProvider())
            .then(({ user }) => {
                handleCreateUserProfile({
                    name: user.displayName ?? "",
                    email: user.email ?? null,
                    phoneNumber: user.phoneNumber ?? null,
                });
            })
            .catch(handleSocialAuthError);
    };

    const handleSignupError = (err: AuthError): void => {
        switch (err.code) {
            case "auth/email-already-in-use":
                toast.error(
                    t(
                        "error_signup.email_used",
                        "E-mail address entered is already in use.",
                    ),
                );
                break;
            case "auth/invalid-email":
                toast.error(
                    t(
                        "error_signup.invalid_email",
                        "E-mail address entered is invalid.",
                    ),
                );
                break;
            case "auth/weak-password":
                toast.error(
                    t(
                        "error_signup.weak_password",
                        "Password entered is too weak, please try another.",
                    ),
                );
                break;
            default:
                // auth/operation-not-allowed
                toast.error(
                    t(
                        "error_signup.default",
                        "An unknown error occurred, please try again later.",
                    ),
                );
                captureException(err, (ctx) =>
                    ctx.setExtras({
                        error: err,
                        errorCode: err.code,
                        errorMessage: err.message,
                    }),
                );
                break;
        }
    };

    const handleSocialAuthError = (err: AuthError): void => {
        switch (err.code) {
            case "auth/account-exists-with-different-credential":
                toast.error(
                    t(
                        "error_social_signup.email_used",
                        "E-mail address entered is already in use.",
                    ),
                );
                break;
            case "auth/cancelled-popup-request":
                toast.error(
                    t(
                        "error_social_signup.login_progress",
                        "Log in in progress.",
                    ),
                );
                break;
            case "auth/operation-not-allowed":
                toast.error(
                    t(
                        "error_social_signup.not_allowed",
                        "Social Log in not allowed for your account.",
                    ),
                );
                break;
            case "auth/popup-closed-by-user":
                toast.error(
                    t(
                        "errorSocialPopupClosed",
                        "The popup has been closed by the user before finalizing the operation.",
                    ),
                );
                break;
            case "auth/popup-blocked":
                toast.error(
                    t("error_social_signup.popup", "Popup is blocked."),
                );
                break;
            default:
                // auth/operation-not-allowed
                toast.error(
                    t(
                        "error_social_signup.default",
                        "An unknown error occurred, please try again later.",
                    ),
                );
                captureException(err, (ctx) =>
                    ctx.setExtras({
                        error: err,
                        errorCode: err.code,
                        errorMessage: err.message,
                    }),
                );
                break;
        }
    };

    return (
        <Loader
            loading={!ready}
            className={cx("flex flex-col gap-[52px]", className)}
        >
            <div className="flex flex-col gap-2">
                <span className="typography-h2 font-bold capitalize text-blue-grey-900">
                    {t("common:signUp", "Sign Up")}
                </span>
                <span className="text-blue-grey-900">
                    {t(
                        "alreadyHaveAccount",
                        "Already have a Courtsite account?",
                    )}
                    <Link
                        href={{ pathname: "/login", query: router.query }}
                        passHref
                    >
                        <span className="ml-1 cursor-pointer font-bold capitalize text-primary-600">
                            {t("common:logIn", "Log In")}
                        </span>
                    </Link>
                </span>
            </div>
            <EmailSignupForm onSubmit={handleSignup} />
            <div className="flex flex-col gap-6">
                <div className="flex items-center gap-2">
                    <Line />
                    <span className="font-bold text-blue-grey">
                        {t("or", "or")}
                    </span>
                    <Line />
                </div>
                <div className="flex flex-col gap-3">
                    <Button
                        className="flex w-full gap-2"
                        variant="tertiary"
                        onClick={handleFacebookLogin}
                    >
                        <SocialColoredIcon
                            type="Facebook"
                            props={{ className: "size-5" }}
                        />
                        <span>
                            {t("continueWith", "Continue with {{context}}", {
                                context: "Facebook",
                            })}
                        </span>
                    </Button>
                    <Button
                        className="flex w-full gap-2"
                        variant="tertiary"
                        onClick={handleGoogleLogin}
                    >
                        <SocialColoredIcon
                            type="Google"
                            props={{ className: "size-5" }}
                        />
                        <span>
                            {t("continueWith", "Continue with {{context}}", {
                                context: "Google",
                            })}
                        </span>
                    </Button>
                </div>
            </div>
        </Loader>
    );
};

export { LoginForm, SignupForm };

const EmailSignupForm = ({ onSubmit }: EmailFormProps): JSX.Element => {
    const { t } = useTranslation(["components/Forms/AuthForm", "common"]);
    const {
        register,
        formState: { errors, isSubmitting },
        handleSubmit,
    } = useForm<EmailFormValues>({ criteriaMode: "all", mode: "onChange" });
    const [visible, setVisible] = useState(false);

    const onFinish = (v: EmailFormValues): void => {
        onSubmit?.(v);
    };

    const toggleVisibility = (): void => setVisible((v) => !v);

    return (
        <form onSubmit={handleSubmit(onFinish)} className="flex flex-col gap-8">
            <div className="flex flex-col gap-3">
                <FormGroup
                    label={t("email", "Email")}
                    htmlFor="email"
                    error={errors.email?.message}
                >
                    <Input
                        id="email"
                        className={cx(
                            "typography-sub text-blue-grey-900",
                            errors.email?.message &&
                                "border-2 border-destructive-600",
                        )}
                        type="email"
                        {...register("email", {
                            required: t("emailRequired", "Email is required"),
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: t(
                                    "emailInvalidMessage",
                                    "Invalid email format",
                                ),
                            },
                        })}
                    />
                </FormGroup>
                <div className="flex flex-col gap-1">
                    <FormGroup
                        label={t("password", "Password")}
                        htmlFor="password"
                    >
                        <div className="relative flex flex-col gap-1">
                            <Input
                                id="password"
                                className={cx(
                                    "typography-sub text-blue-grey-900",
                                    errors.password?.message &&
                                        "border-2 border-destructive-600",
                                )}
                                type={visible ? "text" : "password"}
                                {...register("password", {
                                    required: t(
                                        "passwordRequired",
                                        "Password is required",
                                    ),
                                    minLength: {
                                        value: 8,
                                        message: t(
                                            "passwordMinLength",
                                            "More than 8 characters",
                                        ),
                                    },
                                    maxLength: {
                                        value: 64,
                                        message: t(
                                            "passwordMaxLength",
                                            "Password length must not exceed 64 characters.",
                                        ),
                                    },
                                    validate: {
                                        hasUppercase: (v) =>
                                            /[A-Z]/.test(v) ||
                                            t(
                                                "passwordHasUppercase",
                                                "At least one uppercase letter (A-Z)",
                                            ),
                                        hasLowercase: (v) =>
                                            /[a-z]/.test(v) ||
                                            t(
                                                "passwordHasLowercase",
                                                "At least one lowercase letter (a-z)",
                                            ),
                                        hasNumber: (v) =>
                                            /\d/.test(v) ||
                                            t(
                                                "passwordHasNumber",
                                                "At least one number (0-9)",
                                            ),
                                        hasSpecial: (v) =>
                                            /[\W_]/.test(v) ||
                                            t(
                                                "passwordHasSpecial",
                                                "At least one special character (@,#,$,%,etc.)",
                                            ),
                                    },
                                })}
                            />
                            <div
                                className="absolute right-2 top-2.5 h-3.5 w-4 cursor-pointer bg-white text-blue-grey-600"
                                onClick={toggleVisibility}
                            >
                                {visible ? (
                                    <EyeUnfilled />
                                ) : (
                                    <EyeSlashUnfilled />
                                )}
                            </div>
                        </div>
                    </FormGroup>
                    <div
                        className={cx(
                            "typography-tiny flex flex-col gap-1",
                            !errors.password?.message && "hidden",
                        )}
                    >
                        <span className="flex items-center gap-2 font-bold text-destructive-600">
                            <svg
                                viewBox="0 0 14 15"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-3.5 w-3.5"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8.0311 4.3122C7.59289 3.48447 6.40714 3.48447 5.96893 4.31221L2.65665 10.5687C2.2453 11.3457 2.80857 12.2813 3.68774 12.2813H10.3123C11.1915 12.2813 11.7547 11.3457 11.3434 10.5687L8.0311 4.3122ZM7.00001 9.25021C6.67801 9.25021 6.41668 8.98887 6.41668 8.66687V5.75021C6.41668 5.42821 6.67801 5.16687 7.00001 5.16687C7.32201 5.16687 7.58335 5.42821 7.58335 5.75021V8.66687C7.58335 8.98887 7.32201 9.25021 7.00001 9.25021ZM6.41668 10.4169C6.41668 10.7389 6.67801 11.0002 7.00001 11.0002C7.32201 11.0002 7.58335 10.7389 7.58335 10.4169C7.58335 10.0949 7.32201 9.83354 7.00001 9.83354C6.67801 9.83354 6.41668 10.0949 6.41668 10.4169Z"
                                />
                            </svg>
                            {t("passwordMustContain", "Password must contain:")}
                        </span>
                        <div className="flex flex-col gap-1 ps-[22px]">
                            <ErrorDiv
                                error={
                                    !!(
                                        errors.password?.types?.required ||
                                        errors.password?.types?.minLength ||
                                        errors.password?.types?.maxLength
                                    )
                                }
                                message={t(
                                    "passwordMinMaxLength",
                                    "Minimum 8, maximum 64 characters",
                                )}
                            />
                            <ErrorDiv
                                error={!!errors.password?.types?.hasUppercase}
                                message={t(
                                    "passwordHasUppercase",
                                    "At least one uppercase letter (A-Z)",
                                )}
                            />
                            <ErrorDiv
                                error={!!errors.password?.types?.hasLowercase}
                                message={t(
                                    "passwordHasLowercase",
                                    "At least one lowercase letter (a-z)",
                                )}
                            />
                            <ErrorDiv
                                error={!!errors.password?.types?.hasNumber}
                                message={t(
                                    "passwordHasNumber",
                                    "At least one number (0-9)",
                                )}
                            />
                            <ErrorDiv
                                error={!!errors.password?.types?.hasSpecial}
                                message={t(
                                    "passwordHasSpecial",
                                    "At least one special character (@,#,$,%,etc.)",
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-1">
                <Button type="submit" disabled={isSubmitting}>
                    {t("common:signUp", "Sign Up")}
                </Button>
                <span className="typography-tiny text-center text-blue-grey-900">
                    <Trans t={t} i18nKey="termsAndConditionsSignUp">
                        By signing up, I agree to the Courtsite
                        <LinkText href="/terms/service">Terms of Use</LinkText>
                        and
                        <LinkText href="/terms/privacy">
                            Privacy Policy
                        </LinkText>
                        .
                    </Trans>
                </span>
            </div>
        </form>
    );
};

const EmailLoginForm = ({ onSubmit }: EmailFormProps): JSX.Element => {
    const { t } = useTranslation(["components/Forms/AuthForm", "common"]);
    const {
        register,
        formState: { errors, isSubmitting },
        handleSubmit,
    } = useForm<EmailFormValues>();
    const [visible, setVisible] = useState(false);

    const onFinish = (v: EmailFormValues): void => {
        if (typeof onSubmit === "function") onSubmit(v);
    };

    const toggleVisibility = (): void => setVisible((v) => !v);

    return (
        <form onSubmit={handleSubmit(onFinish)} className="flex flex-col gap-8">
            <div className="flex flex-col gap-3">
                <FormGroup
                    label={t("email", "Email")}
                    htmlFor="email"
                    error={errors.email?.message}
                >
                    <Input
                        id="email"
                        className={cx(
                            "typography-sub text-blue-grey-900",
                            errors.email?.message &&
                                "border-2 border-destructive-600",
                        )}
                        type="email"
                        {...register("email", {
                            required: t("emailRequired", "Email is required"),
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: t(
                                    "emailInvalidMessage",
                                    "Invalid email format",
                                ),
                            },
                        })}
                    />
                </FormGroup>
                <div className="flex flex-col gap-1">
                    <FormGroup
                        label={t("password", "Password")}
                        htmlFor="password"
                        error={errors.password?.message}
                    >
                        <div className="relative flex flex-col gap-1">
                            <Input
                                id="password"
                                className={cx(
                                    "typography-sub text-blue-grey-900",
                                    errors.password?.message &&
                                        "border-2 border-destructive-600",
                                )}
                                type={visible ? "text" : "password"}
                                {...register("password", {
                                    required: t(
                                        "passwordRequired",
                                        "Password is required",
                                    ),
                                })}
                            />
                            <div
                                className="absolute right-2 top-2.5 h-3.5 w-4 cursor-pointer bg-white text-blue-grey-600"
                                onClick={toggleVisibility}
                            >
                                {visible ? (
                                    <EyeUnfilled />
                                ) : (
                                    <EyeSlashUnfilled />
                                )}
                            </div>
                        </div>
                    </FormGroup>
                    <Link href="/resetpassword" passHref>
                        <a className="typography-sub cursor-pointer text-end font-bold text-primary-600">
                            {t("forgotPassword", "Forgot password")}
                        </a>
                    </Link>
                </div>
            </div>
            <div className="flex flex-col gap-1">
                <Button type="submit" disabled={isSubmitting}>
                    {t("common:logIn", "Log In")}
                </Button>
                <span className="typography-tiny text-center text-blue-grey-900">
                    <Trans t={t} i18nKey="termsAndConditionsLogIn">
                        By logging in, I agree to the Courtsite
                        <LinkText href="/terms/service">Terms of Use</LinkText>
                        and
                        <LinkText href="/terms/privacy">
                            Privacy Policy
                        </LinkText>
                        .
                    </Trans>
                </span>
            </div>
        </form>
    );
};

const LinkText = ({ href, children }: LinkTextProps): JSX.Element => (
    <Link href={href}>
        <span className="cursor-pointer text-primary-600">{children}</span>
    </Link>
);

const ErrorDiv = ({ error, message }: ErrorDivProps): JSX.Element => (
    <div
        className={cx(
            error ? "text-destructive-600" : "text-success-600",
            "flex items-center gap-3",
        )}
    >
        {error ? (
            <CrossUnfilled className="size-4 fill-destructive-600" />
        ) : (
            <CheckUnfilled className="size-4 text-success-600" />
        )}
        {message}
    </div>
);

// TODO: Remove FormGroup component and rewrite using semantic form elements
type FormGroupProps = {
    label: string;
    htmlFor?: string;
    error?: string;
    description?: string;
    children: ReactNode;
};
const FormGroup = ({
    label,
    htmlFor,
    error,
    description,
    children,
}: FormGroupProps): JSX.Element => (
    <div className="flex flex-col gap-1">
        <label
            htmlFor={htmlFor}
            className="typography-sub font-bold text-blue-grey"
        >
            {label}
        </label>
        {children}
        <span
            className={cx(
                "typography-tiny flex items-center font-bold text-destructive-600",
                !error && "hidden",
            )}
        >
            <span className="flex gap-2">
                <svg
                    viewBox="0 0 14 15"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3.5 w-3.5"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.0311 4.3122C7.59289 3.48447 6.40714 3.48447 5.96893 4.31221L2.65665 10.5687C2.2453 11.3457 2.80857 12.2813 3.68774 12.2813H10.3123C11.1915 12.2813 11.7547 11.3457 11.3434 10.5687L8.0311 4.3122ZM7.00001 9.25021C6.67801 9.25021 6.41668 8.98887 6.41668 8.66687V5.75021C6.41668 5.42821 6.67801 5.16687 7.00001 5.16687C7.32201 5.16687 7.58335 5.42821 7.58335 5.75021V8.66687C7.58335 8.98887 7.32201 9.25021 7.00001 9.25021ZM6.41668 10.4169C6.41668 10.7389 6.67801 11.0002 7.00001 11.0002C7.32201 11.0002 7.58335 10.7389 7.58335 10.4169C7.58335 10.0949 7.32201 9.83354 7.00001 9.83354C6.67801 9.83354 6.41668 10.0949 6.41668 10.4169Z"
                    />
                </svg>
                {error}
            </span>
            <span>{description}</span>
        </span>
    </div>
);

const Line = (): JSX.Element => (
    <div className="h-0 w-full border-0 border-t border-solid border-blue-grey-100" />
);
