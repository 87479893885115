import Link from "next/link";
import { memo } from "react";

import type { UrlObject } from "node:url";
import { LogomarkWithNameBlue } from "@/assets/vectors/logo";
import { cx } from "class-variance-authority";
import type { TFunction } from "i18next";
import { CloseUnfilled } from "./Icons";

declare type Url = string | UrlObject;

type AuthHeaderProps = {
    className?: string;
    path?: Url;
    t: TFunction<"common">;
};

const AuthHeader = memo<AuthHeaderProps>(
    ({ className, t }): JSX.Element => (
        <header
            className={cx(
                "hidden border-0 border-b-0.5 border-solid border-blue-grey-100 bg-white lg:block",
                className,
            )}
        >
            <nav className="mx-auto flex max-w-[1056px] items-center justify-between p-4">
                <Link href="/">
                    <a>
                        <LogomarkWithNameBlue />
                    </a>
                </Link>
                <div className="flex items-center gap-3">
                    <NavLink href="/support" text={t("supportTitle", "Help")} />
                    <NavLink
                        className="font-semibold"
                        href="/signup"
                        text={t("signUp", "Sign up")}
                    />
                    <NavLink
                        className="bg-primary text-white hover:bg-primary/90 hover:text-white"
                        href="/login"
                        text={t("logIn", "Log in")}
                    />
                </div>
            </nav>
        </header>
    ),
);

AuthHeader.displayName = "Header";
export { AuthHeader, AuthHeaderMobile };

const NavLink = ({
    href,
    text,
    className,
}: {
    href: string;
    text: string;
    className?: string;
}): JSX.Element => (
    <Link href={href}>
        <a
            className={cx(
                "typography-h4 link-plain rounded px-4 py-2.5 font-medium text-blue-grey-900 transition-colors",
                className,
            )}
        >
            {text}
        </a>
    </Link>
);

const AuthHeaderMobile = memo<AuthHeaderProps>(
    ({ className, path }): JSX.Element => (
        <div className={cx("flex justify-between p-4 lg:hidden", className)}>
            <LogomarkWithNameBlue />
            <Link href={path || "/"} passHref>
                <a>
                    <CloseUnfilled className="text-blue-grey-900" />
                </a>
            </Link>
        </div>
    ),
);
AuthHeaderMobile.displayName = "HeaderMobile";
